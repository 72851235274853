@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./editor.css";

@layer base {
  .notes-window {
    /* @apply bg-white text-neutral-900 dark:text-white dark:bg-black; */
    @apply antialiased;

    font-size: 16px;
  }
}

.notes-window,
.notes-window * {
  /* @apply bg-white text-neutral-900 dark:bg-black dark:text-white; */
}

input::placeholder,
textarea::placeholder {
  @apply text-black/50 dark:text-white/40;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  @apply text-black/50 dark:text-white/40;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  @apply text-black/40 dark:text-white/40;
}

.react-colorful {
  width: 100% !important;
}

[data-reference-hidden] {
  opacity: 0;
  pointer-events: none;
}

::-webkit-scrollbar {
  @apply w-1 h-1 bg-neutral-500/20;
}

::-webkit-scrollbar-thumb {
  @apply bg-neutral-500/50 rounded-full;
}

input[type="range"] {
  @apply h-2.5 bg-neutral-200 border-0 rounded appearance-none active:bg-neutral-300 transition-all;
  @apply dark:bg-neutral-700 dark:active:bg-neutral-600;

  &::-webkit-slider-thumb {
    @apply appearance-none w-3 h-5 bg-neutral-800 rounded-full active:bg-neutral-900 active:w-4 active:h-6 transition-all;
    @apply dark:bg-neutral-100 dark:active:bg-white;
  }
}
