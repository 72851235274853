@import "../../node_modules/dockview/dist/styles/dockview.css";

html {
  scrollbar-width: none;
}

#dockview {
  height: 1000px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.container {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--dark-background);
}

.title {
  color: #e9f5db;
  font-family: var(--title-font);
}

#createRoomButton {
  margin: 7px;
  padding: 10px;
  font-size: 16px;
  background: none;
  border: 1px solid #e9f5db;
  color: #e9f5db;
  font-family: var(--title-font);
}

#createRoomButton:hover {
  opacity: 0.5;
  cursor: pointer;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* green theme */
/*
:root {
  --black: #2c3321;
  --darkest-background: #718355;
  --dark-background: #87986a;
  --light-primary: #97a97c;
  --lightest-primary: #b5c99a;
  --dark-font: #718355;
  --modal-background: #87986a;
  --modal-background-darker: #cfe1b9;
  --modal-border: #e9f5db;
  --input-box-font-color: #e9f5db;
  --input-box-border: #718355;
  --light-font: #e9f5db;
  --serif-font: "Merriweather", serif;
  --sans-serif-font: "Open Sans", sans-serif;
  --title-font: "Open Sans", sans-serif;
  --light-title-font-color: #e9f5db;
  --light-body-font-color: #e9f5db;
  --code-like-font: "Courier New", Courier, monospace;
}
*/

:root {
  /* #718355; */
  --darkest-background: #1c1c2a;
  --dark-background: #000c18;
  --light-primary: #e4d476;
  --lightest-primary: #efe2a4;
  --dark-font: #718355;
  --modal-background: #2c2c3a;
  /* #E9F5DB; */
  --modal-background-darker: #cfe1b9;
  --modal-border: #e9f5db;
  --input-box-font-color: #e9f5db;
  --input-box-border: #decd5e;
  /* #353d28; */
  --light-font: #e9f5db;
  --lightest-font: #f5f5f5;

  --alert-color: #e4d476;
  --green-color: green;
  --dark-green-color: #46644a;
  --red-color: red;

  --title-font: "Quantico", sans-serif;
  --subtitle-font: "BerkeleyMono", sans-serif;
  --body-font: "BerkeleyMono", sans-serif;
  --serif-font: "Merriweather", serif;
  --sans-serif-font: "Open Sans", sans-serif;
  --light-title-font-color: #e9f5db;
  --light-body-font-color: #e9f5db;
  --code-like-font: "BerkeleyMono", sans-serif;
  --button-font-color: #e9f5db;
  --header-height: 35px;
  --mobile-bottom-panel-height: 55px;

  --right-sidebar-items-width: 300px;
}

.dockview-theme-abyss {
  --dv-tabs-and-actions-container-height: 55px;
}

.tabs-and-actions-container .tabs-container {
  scrollbar-width: none;
}

.tabs-container::-webkit-scrollbar {
  display: none;
}

.tabs-and-actions-container::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: "BerkeleyMono";
  src: local("BerkeleyMono"),
    url("../fonts/berkeley-mono/BerkeleyMono-Regular.woff2") format("woff2"),
    url("../fonts/berkeley-mono/BerkeleyMono-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "BerkeleyMono";
  src: local("BerkeleyMono"),
    url("../fonts/berkeley-mono/BerkeleyMono-Bold.woff2") format("woff2"),
    url("../fonts/berkeley-mono/BerkeleyMono-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "BerkeleyMono";
  src: local("BerkeleyMono"),
    url("../fonts/berkeley-mono/BerkeleyMono-Italic.woff2") format("woff2"),
    url("../fonts/berkeley-mono/BerkeleyMono-Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

.quantico-regular {
  font-family: "Quantico", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.quantico-bold {
  font-family: "Quantico", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.quantico-regular-italic {
  font-family: "Quantico", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.quantico-bold-italic {
  font-family: "Quantico", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.mobile-bottom-panel {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: var(--mobile-bottom-panel-height);
  background-color: var(--darkest-background);
  border-top: 1px solid var(--modal-border);
  z-index: 101;
}

.open-settings,
.open-tabs {
  background: none;
  color: var(--light-primary);
  padding: 5px 15px;
  font-size: 25px;
  border: none;
}

.main-header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: var(--header-height);
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.messages {
  list-style-type: none;
  overflow: auto;
  height: calc(
    100% - 50px
  ); /* 30px is the height of the input box and margin */
  margin: 0;
  padding: 0;
  margin-bottom: 15px;
  width: 100%;
  /* height: 100vh; */
  margin: 0 auto;
  scrollbar-width: none;
}

.message::-webkit-scrollbar {
  display: none;
}

.messages > *:not(:last-child) {
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .messages {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
    overflow-y: scroll;
    /* For future compatibility */
  }

  .messages::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
  }
}

#connections {
  display: flex;
  flex-wrap: wrap;
}

.connection-name {
  display: flex;
  align-items: center;
  margin: 3px;
  margin-right: 11px;
}

.active-dot {
  height: 8px;
  width: 8px;
  background-color: #32a852;
  border-radius: 50%;
  display: inline-block;
  margin-right: 7px;
  box-shadow: 0 0 8px #00ff00;
}

.offline-dot {
  height: 6px;
  width: 6px;
  background-color: gray;
  border-radius: 50%;
  display: inline-block;
  margin-right: 7px;
  box-shadow: 0 0 8px gray;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.typing-animation {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 7px;
  position: relative;
}

.typing-animation::before,
.typing-animation::after {
  content: "";
  display: block;
  width: 5px;
  height: 5px;
  background-color: #00ff00;
  /* Active color */
  border-radius: 50%;
  position: absolute;
  animation: blink 1s infinite;
}

.typing-animation::before {
  left: 0;
}

.typing-animation::after {
  right: 0;
  animation-delay: 0.5s;
  /* Makes the dots blink asynchronously */
}

.message {
  font-family: sans-serif;
  padding: 5px 10px;
  border-radius: 5px;
  max-width: 700px;
  width: 70%;
  margin: 0 auto;
  margin-top: 10px;
}

.user-message {
  background-color: var(--modal-background);
  text-align: right;
}

.other-user-message {
  background-color: var(--darkest-background);
  text-align: left;
}

.gpt-message {
  text-align: left;
}

.connection-button {
  opacity: 0.5;
  cursor: pointer;
  margin: 3px;
}

.active-connection {
  opacity: 1;
  font-weight: bold;
}

.sendButton {
  margin-left: -35px;
  background: none;
  border: none;
  color: var(--input-box-border);
  font-size: 18px;
}

.sendButton:hover {
  cursor: pointer;
  opacity: 0.5;
}

h5 {
  margin-bottom: 0px;
}

#goal-section {
  margin-top: 85px;
}

#goal-button {
  margin-bottom: 5px;
}

#goal-input {
  margin-top: 5px;
  width: 90%;
  resize: vertical;
  border-radius: 0px;
  border: 0px;
}

.goal-input-prominent {
  margin: 5px;
  font-size: 20px;
  width: 40%;
}

.input-box {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid rgb(192, 192, 192);
  background: var(--darkest-background);
  border-radius: 0px;
  color: var(--input-box-font-color);
  font-family: sans-serif;
  padding: 10px;
}

.input-box:focus {
  outline: none;
  border-bottom: 1px solid var(--input-box-border);
}

.suggestion-card {
}

.suggestion-top-row {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.suggestion-card,
.suggestion-confirmation-card {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: var(--modal-background);
  width: 200px;
  /* min-width: 170px; */
  font-family: sans-serif;
  margin: 3px;
}

.suggestion-confirmation-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 320px;
}

.suggestion-confirmation-card > .verdict-buttons-container > .verdict {
  padding: 10px 10px;
  white-space: nowrap;
}

.suggestion-title {
  margin-top: 0px;
  margin-left: 10px;
  margin-bottom: 0px;
  font-family: var(--title-font);
  font-weight: bold;
}

.suggestion-description {
  font-size: 14px;
  overflow: hidden; /* Changed to auto to ensure scrollbar appears only when necessary */
  scroll-behavior: auto;
  position: relative; /* Needed for the absolute positioning of the ::after pseudo-element */
  opacity: 0.8;
}

.solution-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  overflow: visible;
  width: 95%;
}

.solution-properties {
  font-size: 14px;
  opacity: 0.8;
}

.solution-properties p {
  margin: 2px;
}

#top-solutions-title {
  font-family: var(--title-font);
  font-size: 22px;
  margin-bottom: 15px;
}

.top-solutions-cards-container {
  position: relative;
  height: 95%;
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
}

.top-solutions-cards-container::-webkit-scrollbar {
  display: none;
}

.filter-menu {
  font-family: var(--title-font);
  font-size: 13px;
  margin-bottom: 15px;
  background-color: var(--modal-background);
  border-radius: 0px;
  padding: 3px;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.filter-menu-item {
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.filter-checkbox {
  margin-right: 8px;
}

input[type="checkbox"].filter-checkbox:checked:after {
  color: var(--light-primary);
  background-color: var(--light-primary);
}

.solution-list-item {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--modal-background);
  border-top: 1px solid var(--modal-background);

  padding: 5px;
  /* margin-top: 10px; */
  align-items: center;
  opacity: 1;
  border-radius: 5px;
  height: 18px;
  position: relative;
}

.solution-list-item.TBD {
  opacity: 0.5;
}

.solution-list-item.sketch {
  opacity: 0.8;
  border-top: none;
  margin-top: 1px;
  border-bottom: none;
  margin-bottom: 1px;
}

.solution-list-item.sketch .solution-list-item-title {
  font-family: "Helvetica Neue", Arial, sans-serif;
}

.solution-list-item-title {
  font-family: var(--title-font);
  font-size: 12px;
  margin-left: 8px;
  overflow-x: hidden;
  text-wrap: nowrap;
}

.solution-list-item.discard {
  text-decoration: line-through;
  opacity: 0.7;
}

.solution-list-item.fine {
  background-color: var(--dark-green-color);
}

.solution-list-item.up-vote {
  color: var(--green-color);
}

.solution-list-item.down-vote {
  color: var(--red-color);
  opacity: 0.7;
}

.solution-list-item:hover {
  background-color: var(--darkest-background);
  cursor: pointer;
}

.list-item-vote-buttons {
  display: none;
  margin-left: auto;
  align-items: center;
  justify-content: center;
  width: 135px;
}

.solution-list-item:hover .list-item-vote-buttons {
  display: flex;
}

.ranked-solution-card {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 10px;
  border-radius: 5px;
  background-color: var(--modal-background);
  font-family: sans-serif;
  margin-top: 10px;
  cursor: pointer;
  box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.1);
  justify-content: center;
  max-width: var(--right-sidebar-items-width);
}

.ranked-solution-card.preview {
  width: 300px;
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  background-color: var(--darkest-background);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.ranked-solution-card.preview:focus {
  outline: none;
}

.ranked-solution-card.TBD {
  opacity: 0.5;
}

.ranked-solution-title {
  font-family: var(--title-font);
  font-size: 16px;
  font-weight: 400;
}

.ranked-solution-description {
  color: var(--light-font);
  font-size: 13px;
  opacity: 0.8;
}

.ranked-solution-preferences {
  font-size: 12px;
  display: none;
}

.ranked-solution-card.sketch {
  background: none;
  font-style: italic;
  margin-top: 13px;
  margin-bottom: 13px;
}

.ranked-solution-card.sketch .ranked-solution-title {
  opacity: 0.8;
}

.ranked-solution-card.sketch .ranked-solution-description,
.ranked-solution-card.sketch .preferences-container {
  display: none;
}

.ranked-solution-card.sketch:hover .ranked-solution-description,
.ranked-solution-card.sketch:hover .preferences-container {
  display: flex;
}
.ranked-solution-card.sketch:hover {
  border: 2px solid var(--modal-background);
}

.ranked-solution-card.meta {
  margin-top: 13px;
  margin-bottom: 13px;
  border: 1px solid #ccc;
  background: none;
}

.ranked-solution-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.preferences-container {
  display: flex;
  margin-top: 5px;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.verdict-buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 5px 0px;
}

.verdict {
  border: none;
  padding: 5px 5px;
  border-radius: 3px;
  font-weight: bold;
  font-size: 12px;
  width: 20%;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out,
    transform 0.2s, box-shadow 0.2s;
  background-color: transparent;
  color: var(--light-font);
  opacity: 0.3;
}

.verdict:hover {
  color: var(--light-title-font-color);
  opacity: 1;
}

.verdict.active {
}

.not-yet.verdict {
  display: flex;
  align-items: center;
  white-space: normal;
  margin-right: 5px;
}

.not-really-text {
  line-height: 1.2;
  padding-left: 5px;
  padding-right: 10px;
}
.vote {
  background: none;
  border: none;
  color: var(--light-body-font-color);
  width: 20%;
  opacity: 0.5;
}

.vote:hover {
  cursor: pointer;
  opacity: 1;
}

.vote-buttons-container {
  display: flex;
  align-items: center;
}

.up.vote.active {
  color: rgb(0, 255, 0);
  opacity: 0.8;
}

.down.vote.active {
  color: rgb(255, 0, 0);
  opacity: 0.8;
}

.ranked-solution-card.solution-fine,
.suggestion-card.solution-fine,
.suggestion-confirmation-card.solution-fine {
  background-color: var(--dark-green-color);
  color: #ffffff;
}

.ranked-solution-card.solution-discarded,
.suggestion-card.solution-discarded {
  opacity: 0.5;
}

.ranked-solution-card.solution-discarded > *:not(.ranked-solution-title) {
  display: none;
}

.suggestion-card.solution-discarded > *:not(.suggestion-title, .thumbnail) {
  display: none;
}

.elo-display {
  font-size: 12px;
  color: var(--light-font);
  opacity: 0.65;
  display: none;
  /* unless turned on by nerd mode */
}

.glowing-animation {
  animation: glow 1s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    box-shadow: 0 0 5px yellow;
  }

  to {
    box-shadow: 0 0 20px yellow;
  }
}

.glowing-open-sidebar {
  animation: glowIcon 1s ease-in-out infinite alternate;
}

@keyframes glowIcon {
  from {
    color: var(--input-box-border);
  }

  to {
    color: var(--lightest-primary);
  }
}
/* 
html,
body {
  height: 100%;
  margin: 0;
  font-family: var(--sans-serif-font);
   color: var(--light-body-font-color); 
  overscroll-behavior-y: none;
} */

#main-container {
  position: absolute;
  top: 35px;
  height: calc(100vh - var(--header-height));
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 768px) {
  #main-container {
    height: calc(
      100vh - var(--mobile-bottom-panel-height) - var(--header-height)
    );
  }
}
.open-sidebar {
  display: none;
}

.new-messages-badge {
  display: none;
}

.left-sidebar,
.middle-section {
  box-sizing: border-box;
  max-height: 100vh;
  overflow: hidden;
}

.left-sidebar {
  flex: 1;
  background-color: var(--darkest-background);
  padding: 10px;
  max-width: 20%;
}

.middle-section {
  flex: 3;
  background-color: var(--darkest-background);
  display: flex;
  flex-direction: column;
}

.welcome-text {
  text-align: center;
}

.right-sidebar {
  flex: 1;
  background-color: var(--dark-background);
  padding: 10px;
}

.left-sidebar-close,
.right-sidebar-close {
  display: none;
}

.right-sidebar-bottom {
  min-height: 100px;
}
@media (max-width: 768px) {
  .left-sidebar {
    position: fixed;
    top: 0;
    width: 90vw;
    height: calc(100vh - var(--mobile-bottom-panel-height));
    z-index: 100;
    transition: left 0.25s, right 0.25s;
  }

  .left-sidebar {
    left: -90vw;
    max-width: 90vw;
  }

  .left-sidebar.open {
    left: 0px;
  }

  .open-sidebar {
    /* button */
    display: flex;
    top: 50%;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: var(--input-box-border);
    border-radius: 5px;
    cursor: pointer;
    font-size: 20px;
  }

  .open-left {
    left: 10px;
  }

  .open-right {
    right: 10px;
  }

  .open-right.highlight {
    color: var(--light-primary);
  }
  .left-sidebar-close,
  .right-sidebar-close {
    display: block;
    position: absolute;
    top: 10px;
    cursor: pointer;
    font-size: 24px;
    padding: 5px;
  }

  .left-sidebar-close {
    right: 10px;
  }

  .right-sidebar-close {
    left: 10px;
  }

  .notification-badge {
    position: absolute;
    top: -8px;
    right: 1px;
    color: white;
    border-radius: 50%;
    font-size: 0.8em;
  }

  .new-messages-badge {
    position: absolute;
    top: -8px;
    right: 1px;
    color: white;
    border-radius: 50%;
    font-size: 0.8em;
  }
}

.group-messages-badge,
.ai-messages-badge {
  position: absolute;
  top: -8px;
  right: 1px;
  color: white;
  border-radius: 50%;
  font-size: 0.8em;
}

#initial-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

#chat-section {
  border-radius: 8px;
  height: 100%;
  border-radius: 8px;
  background-color: var(--dark-background);
}

#top-solutions {
  flex: 1;
  /* width: 300px; */
  padding-left: 20px;
  padding-right: 10px;
  /* overflow-y: auto; */
  height: calc(100vh - var(--header-height));
  overflow: hidden;
}

@media (max-width: 768px) {
  #top-solutions {
    height: calc(100vh - 100px);
  }
}
.subtitle {
  margin-top: -7px;
  opacity: 0.5;
  font-family: var(--subtitle-font);
}

#user-info-header {
  display: flex;
  flex-direction: row;
  justify-content: right;
  padding: 5px 10px;
  position: relative;
  align-items: center;
}

.credits-container {
  display: flex;
  align-items: center;
  margin-right: 15px;
  opacity: 0.5;
  font-family: var(--code-like-font);
}

.room-finances-container {
  font-family: var(--code-like-font);
}

#user-info {
  margin-right: 10px;
  font-family: var(--title-font);
}

#user-section {
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for better visibility */
}

#logo-section {
  padding: 5px 10px;
  border-bottom-right-radius: 8px;
  font-family: var(--title-font);
}

@media (max-width: 768px) {
  #user-section {
    border-bottom: 1px solid var(--modal-border);
    border-left: 1px solid var(--modal-border);
  }

  #logo-section {
    border-bottom: 1px solid var(--modal-border);
    border-right: 1px solid var(--modal-border);
  }
}

a {
  text-decoration: none;
  color: var(--light-body-font-color);
}

.message a {
  text-decoration: underline;
}

.message a:hover {
  opacity: 0.5;
}

.solution-chip {
  background-color: var(--modal-background);
  border-radius: 5px;
  padding: 2px 6px;
  color: var(--button-font-color);
  font-size: 90%;
  margin-bottom: 3px;
  display: inline-block;
  cursor: pointer;
}

.invite-participants {
  color: var(--light-font);
  cursor: pointer;
  margin-top: 3px;
  opacity: 0.6;
}

.invite-participants:hover {
  opacity: 1;
}

#qr-code {
  margin-top: 20px;
}

#qr-code img {
  width: 200px;
  height: 200px;
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

#link-text {
  color: black;
}
.close-button {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close-button:hover,
.close-button:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

#room-info {
  display: flex;
  flex-direction: column;
  margin-top: 55px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: var(--dark-background);
  max-width: 450px;
  font-family: var(--title-font);
}

#room-id {
  color: var(--light-font);
  margin-bottom: 10px;
  font-family: var(--code-like-font);
  font-size: 14px;
  opacity: 0.3;
}

#goal-box {
  color: var(--input-box-font-color);
  border-radius: 8px;
  padding: 10px;
  margin-top: 10px;
}

#goal-box.focused > div {
  color: white;
}

#goal-box.focused > div > i {
  color: white;
}

#goal-box.focused > textarea {
  border-color: white;
}
#goal-title {
  color: var(--light-font);
  opacity: 0.6;
  font-size: 13px;
}

#startButtonContainer {
  display: flex;
  align-items: center;
  margin-top: 24px;
}

#startButton {
  margin: 0 auto;
  font-size: 23px;
  margin-top: 10px;
  background: none;
  border: none;
  color: var(--light-title-font-color);
  font-family: var(--title-font);
}

#play-icon {
  margin-left: 6px;
  color: var(--light-title-font-color);
  font-size: 20px;
  opacity: 0.6;
}

#pen-icon {
  margin-left: 4px;
  font-size: 10px;
  color: var(--light-font);
}

#startButton:hover {
  cursor: pointer;
  opacity: 0.5;
}

#form {
  display: flex;
  height: 30px;
  margin: auto;
  width: 500px;
  margin-bottom: 20px;
  align-items: center;
}

@media (max-width: 768px) {
  #form {
    width: 80%;
  }

  .intercom-lightweight-app {
    display: none;
  }
}

#input {
  width: 100%;
  resize: none;
  line-height: 13px;
  height: 13px;
  font-size: 13px;
  background-color: var(--dark-background);
}

#welcome-message {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-height: 1000px;
}

#welcome-text {
  font-size: 18px;
  color: var(--light-title-font-color);
  opacity: 0.8;
  font-family: var(--code-like-font);
  margin-bottom: 20px;
}

#drop-box {
  width: 350px;
  height: 50px;
  border: none;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  margin: 10px 0;
  margin-bottom: 20px;
  background-color: var(--modal-background);
  transition: background-color 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

#drop-box:hover,
#drop-box.dragover {
  background-color: var(--modal-background--darker);
  border: 2px dashed var(--light-primary);
}

/* Hide the actual file input but make the drop box clickable */
#file-input {
  display: none;
}

#file-drop-text {
  opacity: 0.7;
}

.thumbnail {
  display: block;
  width: 100%;
  height: 200px;
  overflow: hidden;
  margin-top: 5px;
}

.thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* This makes the image cover the available space without stretching */
  object-position: center;
  /* This centers the image within the container */
}

.top-solution-thumbnail {
  /* height: 80px; */
  margin-bottom: 10px;
  border-radius: 5px;
}

.hamburger-menu {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.time-bucket {
  opacity: 0.6;
  text-align: right;
  font-family: var(--code-like-font);
}

.room-list {
  position: absolute;
  display: flex;
  flex-direction: column;
  /* margin-top: 20px; */
  padding: 10px;
  right: 0;
  background-color: var(--modal-background);
  min-width: 310px;
  border-radius: 5px;
  overflow-y: auto;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2);
  max-height: 100vh;
}

.room-list-item {
  padding: 10px 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.6);
  cursor: pointer;
  font-family: var(--title-font);
}

.room-list-item:hover {
  opacity: 0.5;
}

.rooms-list a {
  color: var(--light-body-font-color);
  text-decoration: none;
  display: block;
}

.room-users {
  margin-top: 6px;
  margin-bottom: 6px;
  opacity: 0.7;
}

.hamburger-menu:hover .rooms-list {
  display: flex;
  flex-direction: column;
}

.hidden {
  display: none;
}

.creatorsWithStats-section {
  display: none;
}

#creatorsWithStats {
  display: none;
}

.creator-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  background-color: var(--modal-background);
  border-radius: 5px;
  padding: 8px;
}

#creatorsWithStats-title {
  color: var(--dark-font);
  margin-top: 15px;
}

.creator-name {
  font-size: 16px;
}

.creator-description {
  font-size: 13px;
  margin-top: 6px;
}

.creator-stats {
  margin-top: 6px;
  font-size: 13px;
  opacity: 0.65;
}

.creator-solutions {
  margin-top: 6px;
  font-size: 11px;
  opacity: 0.65;
}

@keyframes fadeInGlow {
  0% {
    opacity: 0;
    box-shadow: 0 0 5px yellow;
  }

  50% {
    opacity: 1;
    box-shadow: 0 0 10px yellow;
  }

  100% {
    opacity: 1;
    box-shadow: none;
  }
}

.new-solution {
  animation: fadeInGlow 2s ease-out forwards;
}

.img-visible {
  opacity: 1;
}

.multi-choice-container {
  max-width: 70%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  /* margin-top: 15px; */
}

.multi-choice-title {
  margin-bottom: 15px;
  max-width: 80%;
  display: flex;
  flex-direction: column;
  font-family: var(--title-font);
  font-weight: 400;
}

.multi-choice-buttons {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.choose-one-text {
  font-family: var(--code-like-font);
  font-size: 14px;
  opacity: 0.8;
  /* animation: fadeIn 0.5s ease-out forwards; */
  color: var(--light-primary);
}

#type-below-text {
  margin-top: 80px;
  font-size: 14px;
  opacity: 0.5;
  font-style: italic;
  animation: fadeIn 0.5s ease-out forwards;
  font-family: var(--sans-serif-font);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  /* to {
    opacity: 1;
  } */
}

.option-button {
  background-color: var(--button-bg-color);
  /* border: 1px solid var(--light-font); */
  color: var(--button-font-color);
  margin-bottom: 10px;
  margin-top: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 5px;
  min-width: 90px;
  max-width: 150px;
  display: flex;
  min-height: 30px;
  flex-direction: column;
  font-family: var(--code-like-font);
  /* animation: fadeIn 0.5s ease-out forwards; */ /* TODO: add the nice smoothness back in later */
  position: relative;
  text-align: left;
  flex: 1;
}

.option-button.hotkeys-visible {
  padding-bottom: 30px;
}

.option-text {
}

.hotkey-text {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: 10px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--button-font-color);
  opacity: 0.6;
  font-size: 0.75em;
  /* Removed margin: 0 auto; since it's not needed with absolute positioning */
}

@media (max-width: 768px) {
  .hotkey-text {
    display: none !important;
    /* Force hiding hotkey text on mobile */
  }
}

.option-button.hover {
  background-color: var(--button-hover-bg-color);
}

.option-button.active {
  opacity: 1;
  /* background-color: var(--light-primary); */
}

.option-button:focus {
  outline: none;
}

.option-button.active-submitted {
  opacity: 1;
}

.option-button.inactive-submitted {
  opacity: 0.2;
}
#thinking-animation {
  margin-top: 10px;
}

.loader {
  border: 4px solid var(--modal-background);
  border-top: 4px solid var(--dark-background);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  opacity: 0.5;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--light-primary);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--light-primary);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

#nerd-mode-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

#nerd-mode-text {
  margin-left: 10px;
  opacity: 0.5;
}

.time-left-container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  margin: 0 auto;
  width: 100%;
}

.time-left-bar {
  margin-top: 10px;
  background-color: var(--modal-background);
  opacity: 0.8;
  border-radius: 4px;
  margin: 0 auto;
  height: 8px;
  width: 500px;
}

.solution-meta {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.done-voting-button {
  border-radius: 5px;
  padding: 7px;
  border: 1px solid var(--light-font);
  opacity: 0.75;
  text-align: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  height: 20px;
  display: flex;
}

.done-voting-button.done-voting-inactive {
  border: none;
}
.done-voting-button.done-voting-active:hover {
  cursor: pointer;
  opacity: 1;
  background-color: var(--darkest-background);
}

.done-voting-button.clicked {
  border-radius: 5px;
  padding: 7px;
  border: 1px solid var(--light-font);
  opacity: 1;
  display: none;
}

.more-options-button {
  opacity: 0.5;
  border: 1px solid var(--light-font);
  border-radius: 5px;
  padding: 7px;
  cursor: pointer;
  height: 20px;
  margin: 10px;
  display: flex;
  align-items: center;
}

.more-options-button:hover {
  opacity: 1;
}

.below-suggestion-buttons {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: var(--title-font);
}

.enter-icon-button {
  opacity: 0.5;
  transition: opacity 0.2s;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
}

.enter-icon {
  margin-top: 10px;
  /* Hide by default */
  opacity: 0.5;
  transition: opacity 0.2s;
  /* Smooth transition for opacity changes */
  flex-direction: row;
  align-items: center;
}

.enter-icon:hover,
.enter-icon.active {
  /* Add styles for hover and active state */
  opacity: 1;
  cursor: pointer;
}

#submit-text {
  margin-right: 5px;
}

.cmd-icon {
  font-size: 14px;
}

#username-input {
  width: 80%;
  padding: 10px;
  margin-bottom: 10px;
}

#email-input {
  width: 80%;
  padding: 10px;
  margin-bottom: 10px;
}

#submit-username {
  padding: 7px;
  width: 20%;
  border-radius: 0px;
  color: var(--input-box-font-color);
  border: 2px solid var(--light-primary);
  background-color: var(--darkest-background);
  cursor: pointer;
  font: var(--title-font);
  margin-top: 17px;
}

#submit-username:hover {
  opacity: 0.5;
}

#username-input:focus {
  outline: none;
}

.messages-end {
  margin-bottom: 120px;
}

.chat-selector-container {
  display: flex;
  flex-direction: row;
  /* Change to row to display tabs in a row */
  justify-content: center;
  /* Center the tabs */
  background-color: var(--darkest-background);
  padding-top: 20px;
  /* border-bottom: 1px solid white */
}

.top-solutions-tab-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  margin-bottom: 5px;
  font-family: var(--title-font);
  height: 30px;
}

.top-solutions-lightbulb {
  margin-right: 5px;
}

.tab-title {
  font-size: 16px;
  margin-bottom: 5px;
  font-family: var(--title-font);
}

.custom-tab {
  padding-left: 8px;
  padding-top: 5px;
  border-radius: 8px 8px 0 0;
  cursor: pointer;
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  width: 180px;
  box-sizing: border-box;
}

.inactive-group .chat-preview {
  opacity: 0.3;
}

.active-group .active-tab .custom-tab {
  opacity: 1;
  border-bottom: 2px solid transparent;
}

.chat-option.unread .chat-title,
.chat-option.unread .chat-preview {
  font-weight: bold;
}

.chat-option-flash {
  animation: flashAnimation 1s infinite alternate;
}

@keyframes flashAnimation {
  0% {
    background-color: var(--dark-background);
  }

  100% {
    background-color: var(--light-primary);
  }
}

.chat-preview {
  font-weight: normal;
  font-size: 14px;
  color: var(--light-font);
  opacity: 0.5;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.chat-notification-badge {
  background-color: var(--alert-color);
  color: black;
  border-radius: 50%;
  padding: 5px;
  font-size: 12px;
  margin-left: 5px;
}

.chat-participants {
  display: flex;
  position: absolute;
  right: 10px;
}

.chat-participant-circle {
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  background-color: var(--dark-background);
  border: 1px solid var(--modal-border);
  margin-left: -5px;
  text-align: center;
  color: var(--lightest-primary);
  font-family: var(--title-font);
}

.active-circle {
  color: var(--light-font);
  border: 2px solid var(--light-font);
}

/* .chat-selector-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .menu-row-chat-button {
    position: relative;
    background-color: var(--modal-background);
    border: 2px solid var(--light-primary);
    color: var(--light-font);
    padding: 10px 20px;
    text-align: center;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
    cursor: pointer;
    border-radius: 8px;
  }
  
  .menu-row-chat-button:hover {
    background-color: var(--modal-background-darker);
    color: var(--dark-background);
  } */

.message p {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  /* Add any other styles to match your desired look */
}

.source-link-container {
  opacity: 0.3;
  margin-bottom: 5px;
  font-size: 14px;
  text-align: left;
}

.source-link-container:hover {
  opacity: 1;
}

span.size-24 {
  font-size: 12px;
  font-variation-settings: "OPSZ" 12;
}

.elo-chart {
  display: none;
}
.modal-backdrop {
  /* position: fixed; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.invite-link-container {
  background-color: #f0f0f0;
  padding: 10px;
  font-family: "Courier New", Courier, monospace;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.invite-modal {
  background-color: green;
  position: absolute;
  width: 200px;
}

.modal-overlay {
  background-color: var(--alert-color);
}

.copy-icon {
  cursor: pointer;
  background: none;
  border: none;
}
.copy-success-message {
  bottom: 20px;
  background-color: #4caf50;
  /* Green background for success */
  color: white;
  padding: 10px;
  border-radius: 5px;
  position: absolute;
}
.error-box {
  background-color: red;
  color: white;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
}

#startButtonContainer button {
  margin: 5px;
  padding: 7px 15px;
  border-radius: 5px;
  color: var(--input-box-font-color);
  border: 1px solid var(--light-font);
  background-color: var(--darkest-background);
  font-family: var(--code-like-font);
  cursor: pointer;
  font-weight: normal; /* Make text bold to stand out */
}

#startButtonContainer button:hover {
  opacity: 0.7; /* Slightly less opaque on hover for better UX */
}

#startButtonContainer button:disabled {
  /* background-color: var(--modal-background); */
  opacity: 0.1;
  cursor: not-allowed;
}

#submitGoalButton {
  background-color: var(
    --light-primary
  ); /* Different color for submit to distinguish it */
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#justSolveItButton {
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.view-mode-toggle {
  display: flex;
  margin-bottom: 10px; /* Add some space below the toggle */
  max-width: var(--right-sidebar-items-width);
  width: 100%;
  align-items: center;
}

.menu-row-button {
  background: none;
  border: none;
  color: #ccc; /* Light grey color for icons */
  font-size: 16px;
  cursor: pointer;
  padding: 8px 12px;
  transition: color 0.3s ease;
  vertical-align: middle;
  margin-right: 5px;
}

.menu-row-button.active {
  color: #fff; /* White color for active icon */
  background-color: var(
    --modal-background
  ); /* Blue background for active button */
  border-radius: 10px; /* Rounded edges for active button */
}

.menu-row-button:hover {
  background-color: var(
    --modal-background
  ); /* Blue background for active button */
  border-radius: 10px; /* Rounded edges for active button */
}

.menu-row-button:not(.active):hover {
  color: #ddd; /* Slightly lighter grey on hover for inactive buttons */
}

.top-solution-header {
  display: flex;
  align-items: center;
}

.generate-solutions-button {
  border: none;

  width: 32px;
  /* border: 1px solid #ccc; */
  border-radius: 10px;
  cursor: pointer;
  background: none;
  font-size: 16px;
  /* transition: background-color 0.3s, transform 0.3s; */
}

.generate-solutions-button:hover {
  background-color: var(--modal-background);
}

.generate-solutions-button:active,
.generate-solutions-button:focus {
  background-color: #e0e0e0; /* Slightly darker to indicate active/focus state */
}

.vertical-bar {
  margin-left: 5px;
  margin-right: 5px;

  border-left: 2px solid var(--modal-background);
  height: 24px;
  align-self: "center";
}

.octopus-ai-container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 420px;
  height: 420px;
  transition: all 0.5s ease-in-out;
}

/* .octopus-ai-container canvas {
  background: transparent; 
} */

.octopus-ai-container.left-octopus {
  width: 100%;
}

/* @keyframes swerve {
  0% {
    transform: translateX(0) scale(1.5);
  }
  50% {
    transform: translateX(50px) scale(1.2); 
  }
  100% {
    transform: translateX(0) scale(1); 
  }
}

.octopus-ai-container-small {
  animation: swerve 1s ease forwards;
} */

.thinking {
  margin-bottom: 8px;
  margin-top: 8px;
  opacity: 0.3;
}

.function {
  opacity: 0.8; /* Adjusted for better readability */
  font-size: 13px;
  margin-bottom: 8px;
  margin-top: 8px;
  font-family: "Source Code Pro", monospace; /* Monospace font for code-like appearance */
  background-color: #282c34; /* Dark background similar to many code editors */
  color: #abb2bf; /* Soft white-grey text color for the main text */
  padding: 8px; /* Padding to ensure the text doesn't touch the edges */
  border-radius: 4px; /* Optional: rounded corners for aesthetics */
  white-space: pre; /* Preserve whitespace and formatting */
  overflow-x: auto; /* Enable horizontal scrolling if the code is too long */
}

/* Additional styles for syntax highlighting */
.function .keyword {
  color: #c678dd;
} /* Purple for keywords */
.function .string {
  color: #98c379;
} /* Green for strings */
.function .number {
  color: #d19a66;
} /* Orange for numbers */
.function .comment {
  color: #5c6370;
} /* Grey for comments */

.multiple_choice {
  /* not sure if this is real anymore? */
  margin-top: 20px;
  font-family: var(--code-like-font);
}

.message pre {
  white-space: pre-wrap; /* Allows text to wrap */
  word-wrap: break-word; /* Breaks the word to prevent overflow */
  overflow-wrap: break-word; /* Ensures words break to prevent overflow */
}

.message code {
  white-space: inherit; /* Inherits the wrapping from the parent `pre` element */
}

/* a kind of button */
.show-function-results {
  background-color: var(--dark-background);
  color: var(--button-font-color);
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  min-height: 30px;
  flex-direction: column;
  font-family: var(--code-like-font);
  position: relative;
  text-align: left;
  border: 1px solid var(--modal-background);
}

.show-function-results:hover {
  opacity: 0.5;
}

#preference-summary {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-top: 20px;
  font-size: 14px;
  width: 100%;
}

.preferenceItem {
  background-color: var(--modal-background);
  border-radius: 5px;
  margin-top: 10px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 95%;
  text-align: left;
  opacity: 0.7;
}

.preferenceItem:hover {
  opacity: 1;
}

.preferenceTitle {
  font-size: 16px;
  font-family: var(--title-font);
  align-items: left;
  opacity: 0.75;
}

.preferenceSubtitle {
  font-size: 13px;
  font-family: var(--title-font);
  align-items: left;
  opacity: 0.5;
  margin-top: 5px;
}

/* For Dockview */
#root {
  height: 100vh;
  width: 100vw;
}

.doc-wrapper {
  height: 100%;
  width: 100%;
}

.doc {
  height: 100%;
  width: 100%;
  overflow: auto;
}

.dv-render-overlay {
  z-index: auto;
}

main .message {
  padding: 0 0 var(--ck-sample-base-spacing);
  background: var(--ck-sample-color-green);
  color: var(--ck-sample-color-white);
}

main .message::after {
  content: "";
  z-index: -1;
  display: block;
  height: 10em;
  width: 100%;
  background: var(--ck-sample-color-green);
  position: absolute;
  left: 0;
}

main .message h2 {
  position: relative;
  padding-top: 1em;
  font-size: 2em;
}

footer {
  margin: calc(2 * var(--ck-sample-base-spacing)) var(--ck-sample-base-spacing);
  font-size: 0.8em;
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
}

iframe#webpack-dev-server-client-overlay {
  display: none;
}

.context-ingestors {
  font-family: var(--title-font);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.context-sources {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.context-source-icon {
  width: 40px;
  height: 40px;
  margin: 10px;
  border: 1px solid var(--light-font);
  border-radius: 4px;
  padding: 6px;
  justify-content: center;
  align-items: center;
}

.context-source-icon:hover {
  background-color: var(--modal-background);
  cursor: pointer;
  opacity: 0.7;
}

.custom-tooltip {
  color: black;
  font-size: 12px;
  z-index: 100;
}

.elo-chart-time {
  opacity: 0.5;
}

.queue-container {
  padding: 20px;
  height: 95%;
  overflow-y: auto;
}

.match-data-section {
  margin-bottom: 20px;
}

.match-data-section h3 {
  margin-bottom: 10px;
}

.match-data ul {
  list-style-type: none;
  padding-left: 20px;
}

.match-data li {
  margin-bottom: 5px;
}

.summary-metadata-container {
  background: var(--modal-background);
  padding: 5px;
}

.job-type-section {
  border-top: 1px solid var(--modal-border);
}

.job-summary {
  margin-bottom: 15px;
}

.job-summary p {
  padding: 3px;
  border-top: 1px solid var(--modal-background);
  opacity: 0.8;
  margin: 0px;
}

.data-preview {
  cursor: pointer;
  color: var(--link-color);
}

.json-data {
  max-height: 400px;
  overflow-y: auto;
  background: var(--modal-background);
  padding: 10px;
  border: 1px solid var(--modal-border);
  border-radius: 5px;
}

.modal-content {
  background-color: black;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid var(--light-primary);
  width: 80%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: var(--title-font);
  font-size: 13px;
}

.modal-field-title {
  margin-bottom: 5px;
}

.modal-field-description {
  margin-bottom: 5px;
}

.modal-field-input {
  font-family: var(--code-like-font);
  margin-bottom: 15px;
  border-radius: 0px;
  border: none;
  outline: none;
  width: 100px;
  background-image: linear-gradient(
    to right,
    var(--light-primary) 0%,
    var(--light-primary) 100%
  );
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 0% 1px;
  transition: background-size 0.2s ease;
}

.modal-field-input:focus {
  background-size: 100% 1px;
}

.sender {
  color: var(--dark-green-color);
  /* opacity: 0.5; */
  /* font-style: italic; */
  margin-bottom: 3px;
  font-weight: 700;
}
