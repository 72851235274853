/* a hacky way to override the styles for party theme */

.rosarivo-regular {
  font-family: "Rosarivo", cursive;
  font-weight: 400;
  font-style: normal;
}

.rosarivo-regular-italic {
  font-family: "Rosarivo", cursive;
  font-weight: 400;
  font-style: italic;
}

.molengo-regular {
  font-family: "Molengo", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.caveat-octopus {
  font-family: "Caveat", cursive;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

:root {
  /* #718355; */
  --main-background: white;
  --darkest-background: white;
  --dark-background: white;
  --light-primary: #e4d476;
  --lightest-primary: #efe2a4;
  --dark-font: black;
  --dark-accent: rgb(157, 156, 156);
  --modal-background: #fcf9f1;
  /* #E9F5DB; */
  --modal-background-darker: #f3f0e7;
  --modal-border: gray;
  --modal-border-lighter: rgb(181, 180, 180);

  --input-box-font-color: gray;
  --input-box-border: #decd5e;
  --light-font: gray;
  --lightest-font: #f5f5f5;

  --alert-color: #e4d476;
  --red-color: red;
  --green-color: green;
  --dark-green-color: #46644a;

  --title-font: "Rosarivo", cursive;
  --subtitle-font: "BerkeleyMono", sans-serif;
  --body-font: "BerkeleyMono", sans-serif;
  --serif-font: "Rosarivo", cursive;
  --sans-serif-font: "Rosarivo", cursive;
  --light-title-font-color: gray;
  --light-body-font-color: gray;
  --code-like-font: "Rosarivo", sans-serif;
  --button-font-color: gray;
  --header-height: 35px;
  --mobile-bottom-panel-height: 55px;

  --right-sidebar-items-width: 300px;

  --body-font-size: 11px;
  --title-font-size: 12px;

  --control-panel-height: 190px;
}

@media screen and (max-width: 768px) {
  :root {
    --control-panel-height: 120px;
    --title-font-size: 16px;
    --body-font-size: 14px;
  }
}

.party-page-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.party-page-content {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.party-page {
  position: relative;
  height: 100vh;
  overflow: hidden;
  background-color: var(--main-background);
  display: flex;
}
.party-header {
  position: relative;
  width: 100%;
  height: var(--header-height);
  /* background-color: white; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tactile-button {
  color: var(--dark-green-color);
  border: 1px solid var(--dark-green-color);
  background-color: var(--lightest-primary);
  padding: 8px 16px;
  font-family: var(--serif-font);
  font-size: var(--body-font-size);
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 2px 2px 0 var(--dark-green-color);
}

.tactile-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.tactile-button:hover {
  background-color: var(--light-primary);
}

.tactile-button.pressed {
  transform: translate(1px, 1px);
  box-shadow: none;
  background-color: var(--lightest-primary);
}

.tactile-button.clicked {
  animation: quickPress 0.2s ease-in-out;
}

@keyframes quickPress {
  0%,
  100% {
    transform: translate(0, 0);
    box-shadow: 2px 2px 0 var(--dark-green-color);
    background-color: var(--lightest-primary);
  }
  50% {
    transform: translate(1px, 1px);
    box-shadow: none;
    background-color: var(--modal-background-darker);
  }
}

.modal-content {
  background: none;
  border: none;
}

.modal {
  background: none;
}

.modal-field-description {
  font-size: var(--body-font-size);
}

.modal-field-input {
  /* border-bottom: 1px solid var(--input-box-border); */
  font-size: var(--body-font-size);
}

.modal-field-input::placeholder {
  opacity: 0.5;
}

.input-box {
}

.input-box,
.input-box:focus {
  /* border: none; */
  font-family: var(--sans-serif-font);
  color: var(--dark-font);
  border: 1px solid var(--dark-green-color);
}

#input {
  font-size: var(--body-font-size);
  scrollbar-width: none;
  max-width: 500px;
}

#input::-webkit-scrollbar {
  display: none;
}

.ReactModalPortal {
  z-index: 10;
  position: sticky;
}

.ReactModal__Content {
  z-index: 1000;
}

.ReactModal__Content.ReactModal__Content--after-open {
  border: none;
}

#welcome-message {
  margin-top: 50px;
}

#welcome-title {
  color: var(--dark-green-color);
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 15px;
}

@media screen and (max-width: 768px) {
  #welcome-title {
    font-size: 16px;
  }
}

.submit-username {
  width: 70px;
  margin-top: 20px;
}

@media screen and (max-width: 768px) {
  .submit-username {
    width: 90px;
  }
}

#party-start-container {
  display: flex;
  align-items: center;
  margin-top: 24px;
}

#welcome-text {
  font-size: var(--title-font-size);
  margin-bottom: 10px;
  opacity: 1;
  color: var(--dark-green-color);
}

.submit-goal-button {
  width: 70px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 0px;
  padding-right: 0px;
  margin: 5px;
}

.just-solve-it-button {
  background-color: white;
  width: 70px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 3px;
  padding-right: 3px;
  margin: 5px;
}

.enter-icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.enter-icon-button > .material-icons-outlined {
  font-size: 1.8em; /* This makes the icons 20% larger than the container's font size */
}

#goal-input-copy {
  font-size: var(--body-font-size);
  font-family: var(--sans-serif-font);
  resize: none;
  border: none;
}

.goal-input-prominent {
  width: 300px;
}

#logo {
  padding-left: 15px;
  padding-top: 10px;
  font-family: var(--title-font);
}

#logo a {
  color: var(--dark-green-color);
}

.left-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.control-panel-container {
}

.control-panel {
  /* width: 100%;
  height: 100%; */
  position: relative;
  width: 356px;
  height: var(--control-panel-height);
  z-index: 2;
  border: 1px solid var(--dark-green-color);
  margin-bottom: 20px;
}

.rotate-button {
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px 16px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.rotate-button:hover {
  background-color: #2980b9;
}

.user-blobs {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.user-blob {
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  /* background-color: white; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--body-font-size);
  cursor: pointer;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
  transform-origin: center center;
  transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.user-blob .user-blob-content {
  position: relative;
  padding-left: 0.8em;
}

.user-blob .user-blob-content::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background-color: currentColor;
}

.user-blob.online {
  color: var(--green-color);
}

.user-blob.offline {
  opacity: 0.7; /* Make offline users slightly transparent */
}

.user-blob.typing {
  animation: pulse 1.5s infinite;
}

.user-blob.typing::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.7);
  animation: typing-flow 2s linear infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(76, 175, 80, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0);
  }
}

@keyframes typing-flow {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotateAround {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(var(--rotation-angle));
  }
}

.user-blob.rotating {
  animation: rotateAround var(--animation-duration, 0.5s) ease-in-out forwards;
}

/* .control-panel {
  position: relative;
  width: 100%;
  height: 100%;
   width: 180px;
  height: 180px; 
} */

.mission-content {
  font-family: var(--title-font);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  outline: none;
  margin-top: 3px;
}

.mission-content:focus {
  outline: none;
  border: none;
}

.mission-title {
  margin-bottom: 10px;
}

.editable-mission {
  width: 80%;
  text-align: center;
  font-family: var(--title-font);
  margin-top: 10px;

  outline: none;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}

.control-panel p,
.control-panel textarea {
  width: 80%;
  border: none;
  background: transparent;
  text-align: center;
  color: #666;
  font-size: 14px;
}

.control-panel textarea {
  resize: none;
  height: 50px;
}

/* Add this new style for the editable mission text */
.control-panel .editable-mission {
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 5px;
  padding: 5px;
}

.control-panel .editable-mission:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

#party-chat-section {
  flex: 1;
  margin: 0 20px 0 20px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100%;
  scrollbar-width: none;

  /* margin-top: calc(var(--control-panel-height)); */
}

#party-chat-section::-webkit-scrollbar {
  display: none;
}

#party-chat-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 20px;
  background: linear-gradient(
    to bottom,
    var(--main-background) 0%,
    transparent 100%
  );
  pointer-events: none;
  z-index: 1;
}

.top-solutions-sidebar {
  width: 300px;
  height: 100%;
  overflow-y: auto;
  scrollbar-width: none;
}

.top-solutions-sidebar::-webkit-scrollbar {
  display: none;
}

#top-solutions {
  height: 100%;
}

.right-sidebar {
  background: none;
}

.messages {
  color: var(--dark-font);
  height: 100%;
}

.other-user-message.user-message {
  background: none;
}

::-webkit-scrollbar {
  display: none;
}

.message {
  font-size: var(--body-font-size);
  font-family: var(--sans-serif-font);
}

.user-message {
  /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); */
  background-color: var(--modal-background-darker);
  border-radius: 3px;
}

.choose-one-text {
  font-size: var(--body-font-size);
  opacity: 1;
  color: var(--dark-green-color);
  font-style: italic;
  transition: opacity 0.3s ease-in-out;
}

button {
  font-size: var(--body-font-size);
}

.octopus-container {
  position: absolute;
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  transition: all 0.5s ease-in-out;
}

.invite-button {
  z-index: 3;
  position: absolute;
  top: -7px;
  right: 7px;
  min-width: 17px;
  max-width: 90px;
  height: 20px;
  padding: 2px 4px;
  box-shadow: none;
}

@media screen and (max-width: 768px) {
  .invite-button {
    max-width: 130px;
    height: 25px;
  }
}

.invite-button:hover {
  background-color: var(--light-primary);
}

.chat-toggle-button {
  z-index: 3;
  position: absolute;
  bottom: 7px;
  left: 7px;
  min-width: 17px;
  max-width: 90px;
  height: 20px;
  padding: 2px 4px;
  background-color: var(--modal-background-darker);
  box-shadow: 1px 1px 0px 0px var(--dark-green-color);
  /* transition: none; */
}

.chat-toggle-button.pressed {
  background-color: var(--light-primary);

  /* transform: none; */
}

.chat-toggle-button.group {
  left: 60px;
}

.chat-toggle-button.notes {
  left: 133px;
}

.chat-toggle-button.clicked {
  /* animation: none; */
}

@media screen and (max-width: 768px) {
  .chat-toggle-button {
    max-width: 130px;
    height: 25px;
  }
  .chat-toggle-button.group {
    left: 75px;
  }
}

/* .chat-toggle-button:hover {
  background-color: var(--light-primary);
} */

h4 {
  font-size: var(--title-font-size);
}

.suggestion-card {
  padding: 5px;
  padding-left: 11px;
  padding-right: 11px;
}

div.suggestion-card.solution-fine > div.preferences-container > button {
  color: white;
}

.suggestion-top-row {
  margin-top: 5px;
}

.suggestion-title {
  margin-left: 0px;
}

.suggestion-description {
  font-size: var(--body-font-size);
  font-family: var(--sans-serif-font);
  margin-top: 5px;
  margin-bottom: 0px;
}

.below-suggestion-buttons {
  font-size: var(--body-font-size);
}

.suggestion-card {
  border-radius: 0px;
  border: 1px solid var(--dark-green-color);
  transition: all 0.2s ease;
  box-shadow: 2px 2px 0 var(--dark-green-color);
}

.ranked-solution-card {
  border-radius: 0px;
  border: 1px solid var(--dark-green-color);
  transition: all 0.2s ease;
  box-shadow: 2px 2px 0 var(--dark-green-color);
}

.ranked-solution-title {
  margin-left: 0px;
  font-size: var(--title-font-size);
  font-weight: 700;
}

.ranked-solution-description {
  font-size: var(--body-font-size);
  font-family: var(--sans-serif-font);
  margin-top: 5px;
  margin-bottom: 0px;
}

.solution-title {
  margin-left: 0px;
  font-size: var(--title-font-size);
  font-family: var(--sans-serif-font);
  font-weight: 700;
}

.solution-description {
  font-size: var(--body-font-size);
  font-family: var(--sans-serif-font);
  margin-top: 5px;
  margin-bottom: 0px;
}

.solution-fine > .ranked-solution-description {
  color: white;
}

.menu-row-button {
  color: var(--dark-accent);
}

.menu-row-button.active {
  background-color: var(--modal-background-darker);
  color: var(--dark-accent);
}

.top-solution-thumbnail {
  border-radius: 0px;
}

@media screen and (max-width: 768px) {
  .thumbnail {
    height: 100px;
  }
}

.ranked-solution-card.real_option > .solution-type-icon {
  display: none;
}

.solution-list-item.real_option > .solution-type-icon {
  display: none;
}

.solution-type-icon {
  font-size: var(--body-font-size);
  margin-left: -2px;
}

.origin-icon {
  display: none;
}

.solution-chip {
  color: var(--dark-font);
}

.solution-list-item {
  padding: 3px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.solution-list-item.up-vote {
  opacity: 0.9;
}

.solution-list-item.sketch {
  font-family: var(--serif-font);
}

.solution-list-item-title {
  font-size: var(--body-font-size);
}

.solution-list-item:hover {
  background-color: #fbfbfb;
}

.solution-list-item.fine {
  color: white;
  border-radius: 3px;
}

.solution-list-item.fine:hover {
  color: white;
  background-color: var(--dark-green-color);
}

.solution-list-item.fine > div.list-item-vote-buttons > .vote,
.solution-list-item.fine > div.list-item-vote-buttons > .verdict {
  color: white;
}

.verdict {
  box-shadow: none;
}

.verdict.active.discard {
  border: none;
  outline: none;
}

.up.vote.active {
  color: var(--green-color);
}

.title {
  font-weight: bold;
  font-size: 36px;
  margin-bottom: 15px;
  color: var(--dark-green-color);
}

#type-below-text {
  font-size: var(--body-font-size);
  color: var(--dark-green-color);
  margin-top: 35px;
  font-family: var(--serif-font);
}

.option-button {
  color: var(--dark-font);
  opacity: 0.5;

  border-radius: 0px;
  color: var(--dark-green-color);
  border: 1px solid var(--dark-green-color);
  background-color: var(--lightest-primary);
  padding: 8px 16px;
  font-family: var(--serif-font);
  font-size: var(--body-font-size);
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 2px 2px 0 var(--dark-green-color);
  margin: 7px;
  opacity: 1;
  height: auto;
  width: auto;
  min-height: fit-content;
  flex-grow: inherit;
}

.option-button.hotkeys-visible {
  padding-bottom: 8px;
  height: auto;
  flex-grow: inherit;
  min-height: fit-content;
  min-width: fit-content;
}

.hotkey-text {
  position: relative;
  opacity: 0.6;
  color: var(--dark-green-color);
  font-family: var(--sans-serif-font);
  margin-top: 0px;
  margin-bottom: 10px;
}

.done-voting-button {
  border-radius: 0px;
  height: 25px;
  opacity: 1;
}

.done-voting-button.done-voting-active:hover {
  border-radius: 0px;
  height: 25px;
  background-color: var(--light-primary);
}
.more-options-button {
  border-radius: 0px;
  height: 25px;
  background-color: white;
  opacity: 1;
}

#qr-code {
  display: none;
}

@media screen and (max-width: 768px) {
  #qr-code {
    display: block;
  }
}

.close-button {
  margin-right: 0px;
  margin-left: auto;
  background: none;
  border: none;
}

/* p {
  font-size: var(--body-font-size);
} */

#link-text {
  font-size: var(--body-font-size);
}

#invite-text {
  color: var(--dark-green-color);
}

@media screen and (max-width: 768px) {
  #link-text {
    font-size: 12px;
  }
}

.time-left-bar {
  height: 2px;
  background-color: var(--dark-green-color);
  opacity: 0.7;
  border-radius: 0px;
  margin-bottom: 8px;
}

#party-submit-text {
  font-size: var(--body-font-size);
}

.enter-icon {
  margin-top: 4px;
  opacity: 0.6;
  color: var(--dark-green-color);
}

.enter-icon > .material-icons-outlined {
  font-size: 1.2em;
}

@media screen and (max-width: 768px) {
  .enter-icon {
    display: none;
  }
}

.time-left-container {
  margin-top: 25px;
  /* color: var(--light-font); */
}

.orb-pointer {
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  font-family: var(--title-font);
}

#form {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
}

.hotkey-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.multi-choice-container {
  align-items: center;
}

.help {
  display: flex;
  width: 20px;
  height: 20px;
  font-size: 18px;
  color: var(--dark-green-color);
  border-radius: 50%;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  font-family: var(--serif-font);
}

#help-title {
  font-size: 24px;
  font-family: var(--title-font);
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: 800;
  color: var(--dark-green-color);
}

#help-text {
  font-size: var(--body-font-size);
  margin-bottom: 10px;
  color: var(--dark-green-color);
  font-family: var(--serif-font);
}

.confetti {
  z-index: 1000;
}

@media screen and (max-width: 768px) {
  .party-page-content {
    flex-direction: column;
  }

  .left-column {
    width: 100%;
  }

  .sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--dark-green-color);
    opacity: 0.5;
    z-index: 999;
  }

  .mobile-sidebar {
    position: fixed;
    top: 0;
    right: -100%;
    width: 80%;
    height: 100vh;
    background-color: var(--main-background);
    transition: right 0.3s ease-in-out;
    border-left: 1px solid var(--dark-green-color);
    z-index: 1000;
  }

  .mobile-sidebar::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 10px;
    width: 3px;
    height: 40px;
    background-color: var(--dark-green-color);
    border-radius: 2px;
    opacity: 0.7;
  }

  .mobile-sidebar.open {
    right: 0;
  }

  .sidebar-toggle {
    position: fixed;
    width: 40px;
    height: 40px;
    padding-top: 10px;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    z-index: 999;
    font-size: 24px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .close-sidebar {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
}

.thinking {
  opacity: 0.5;
}

.notification-count {
  position: absolute;
  top: -7px;
  right: -10px;
  background-color: var(--dark-green-color);
  color: white;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  padding: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.x-logo-container {
  position: absolute;
  bottom: 16px;
  right: 16px;
  width: 21px;
  height: 21px;
}

.x-logo {
  width: 100%;
  height: 100%;
  fill: var(--dark-green-color);
}

.x-logo:hover {
  opacity: 0.7;
}

.notes-window {
  background-color: white;
  border: 1px solid var(--modal-border);

  /* transition: all 0.3s ease; */
  z-index: 10;
  overflow: hidden;
  /* padding-top: 25px; */
  color: var(--dark-font);
  box-shadow: 2px 2px 0 var(--dark-green-color);
}

.notes-window.minimized {
  border-top: none;
  border-bottom-left-radius: 5px;
  box-shadow: none;
}

.notes-window.minimized .ProseMirror {
  padding-left: 10px;
}

.notes-header {
  display: flex;
  justify-content: space-between;
  background-color: var(--modal-background);
  align-items: center;
  width: 100%;
  position: relative;
  margin-bottom: 10px;
  padding-right: 7px;
  padding-left: 7px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid var(--modal-border-lighter);
}

.notes-header.minimized {
  position: absolute;
  padding-top: 0px;
}

.notes-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-left: auto;
  background-color: var(--modal-background);
  z-index: 2;
  padding-left: 7px;
}

.notes-header-title {
  font-size: var(--title-font-size);
  color: var(--dark-green-color);
  margin-right: 5px;
  font-family: "inter";
}

@media screen and (max-width: 768px) {
  .notes-header-title {
    font-size: 12px;
    align-items: center;
  }
}

.minify-button {
  background: none;
  border: none;
  font-size: 10px;
  cursor: pointer;
  padding: 0px 0px;
  color: var(--dark-green-color);
  opacity: 0.7;
  z-index: 10;
}

.notes-window-drag-handle {
  position: absolute;
  width: 50%;
  left: 50%;
  top: 2px;
  transform: translateX(-50%);
  flex-grow: 1;
  text-align: center;
  /* padding: 5px;  */
  cursor: move;
  user-select: none;
  font-size: 15px;
  color: var(--dark-green-color);
  opacity: 0.7;
}

.ProseMirror p {
  font-family: var(--serif-font);
}
