.ProseMirror {
  .tableWrapper {
    @apply my-6;
  }

  table {
    @apply border-collapse border-black/10 rounded box-border w-full;
    @apply dark:border-white/20;

    td,
    th {
      @apply border border-black/10 min-w-[100px] p-2 relative text-left align-top;
      @apply dark:border-white/20;

      &:first-of-type:not(a) {
        @apply mt-0;
      }

      p {
        @apply m-0;

        & + p {
          @apply mt-3;
        }
      }
    }

    th {
      @apply font-bold;
    }

    .column-resize-handle {
      @apply -bottom-[2px] flex pointer-events-none absolute -right-1 top-0 w-2;

      &::before {
        @apply bg-black/20 h-full w-[1px] ml-2;
        @apply dark:bg-white/20;
        content: "";
      }
    }

    .selectedCell {
      @apply bg-black/5 border-black/20 border-double;
      @apply dark:bg-white/10 dark:border-white/20;
    }

    .grip-column,
    .grip-row {
      @apply items-center bg-black/5 cursor-pointer flex justify-center absolute z-10;
      @apply dark:bg-white/10;
    }

    .grip-column {
      @apply w-[calc(100%+1px)] border-l border-black/20 h-3 left-0 -ml-[1px] -top-3;
      @apply dark:border-white/20;

      &:hover,
      &.selected {
        &::before {
          content: "";
          @apply w-2.5;
        }
      }

      &:hover {
        @apply bg-black/10;
        @apply dark:bg-white/20;

        &::before {
          @apply border-b-2 border-dotted border-black/60;
          @apply dark:border-white/60;
        }
      }

      &.first {
        @apply border-transparent rounded-tl-sm;
      }

      &.last {
        @apply rounded-tr-sm;
      }

      &.selected {
        @apply bg-black/30 border-black/30 shadow-sm;
        @apply dark:bg-white/30 dark:border-white/30;

        &::before {
          @apply border-b-2 border-dotted;
        }
      }
    }

    .grip-row {
      @apply h-[calc(100%+1px)] border-t border-black/20 -left-3 w-3 top-0 -mt-[1px];
      @apply dark:border-white/20;

      &:hover,
      &.selected {
        &::before {
          @apply h-2.5;
          content: "";
        }
      }
      &:hover {
        @apply bg-black/10;
        @apply dark:bg-white/20;

        &::before {
          @apply border-l-2 border-dotted border-black/60;
          @apply dark:border-white/60;
        }
      }

      &.first {
        @apply border-transparent rounded-tl-sm;
      }

      &.last {
        @apply rounded-bl-sm;
      }

      &.selected {
        @apply bg-black/30 border-black/30 shadow-sm;
        @apply dark:bg-white/30 dark:border-white/30;

        &::before {
          @apply border-l-2 border-dotted;
        }
      }
    }
  }
}
