.ProseMirror {
  /* Image Block */
  figure[data-type="imageBlock"] {
    @apply m-0;

    img {
      @apply rounded block w-full;
    }
  }

  /* Block Quote */
  figure[data-type="blockquoteFigure"] {
    @apply my-6 text-black dark:text-white;
  }

  & > blockquote,
  [data-type="blockquoteFigure"] {
    blockquote {
      @apply m-0;

      & > * {
        @apply first:mt-0 last:mb-0;
      }
    }
  }

  /* Columns */
  [data-type="columns"] {
    @apply grid gap-4 mt-14 mb-12;

    &.layout-sidebar-left {
      grid-template-columns: 40fr 60fr;
    }

    &.layout-sidebar-right {
      grid-template-columns: 60fr 40fr;
    }

    &.layout-two-column {
      grid-template-columns: 1fr 1fr;
    }
  }

  [data-type="column"] {
    @apply overflow-auto;
  }
}
