.ProseMirror {
  /* Placeholder Styles */
  .is-empty::before {
    @apply text-black/40 dark:text-white/40 float-left h-0 pointer-events-none w-full;
  }

  &.ProseMirror-focused {
    /* Slashmenu Placeholder */
    > p.has-focus.is-empty::before {
      content: "Type  /  to browse options";
    }

    > [data-type="columns"]
      > [data-type="column"]
      > p.is-empty.has-focus::before {
      content: "Type  /  to browse options";
    }
  }

  /* Default Placeholder */
  & > .is-editor-empty::before {
    content: "write notes here …";
  }

  /* Blockquote Placeholder */
  blockquote .is-empty:not(.is-editor-empty):first-child:last-child::before {
    content: "Enter a quote";
  }

  blockquote + figcaption.is-empty:not(.is-editor-empty)::before {
    content: "Author";
  }

  [data-placeholder][data-suggestion]::before,
  [data-placeholder][data-suggestion] *::before {
    content: none !important;
  }
}
